import "./bootstrap";

import { createApp, h, DefineComponent } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from 'ziggy-js';

import * as Sentry from "@sentry/vue";

const appName = import.meta.env.VITE_APP_NAME || "Laravel";

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./pages/${name}.vue`,
            import.meta.glob<DefineComponent>("./pages/**/*.vue"),
        ),
    setup({ el, App, props, plugin }) {
        const vueApp = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue);

        Sentry.init({
            app: vueApp,
            dsn: "https://35e0866f90a4f1cddaec02f64d30bfe0@errors.rds-online.nl/3",
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                    blockAllMedia: false,
                    maskAllText: false,
                    maskAllInputs: true,
                }),
            ],
            tracesSampleRate: 0.1,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            allowUrls: ["opuspod.nl"],
            beforeSend(event) {
                if (
                    event.exception?.values?.[0]?.stacktrace?.frames?.some(
                        (f) =>
                            f.filename?.includes(
                                `https://www.googletagmanager.com`,
                            ),
                    )
                ) {
                    return null;
                }

                return event;
            },
        });

        vueApp.mount(el);

        return vueApp;
    },
    progress: {
        color: "#386B54",
    },
});
